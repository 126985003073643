import React from "react";
import { useState,useEffect,useRef } from "react";
import './animatie_show.css';
import { FaCheckSquare, FaExclamationTriangle, FaFileSignature } from "react-icons/fa";
import './animatie_show.css';
import axios from "axios";
import Cookies from 'universal-cookie';
import randomstring from "randomstring";
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';

const cookies = new Cookies({ path: '/' });
var __modal_title,__modal_content="";
const Inscriere = () => {
    const [u_nume, set_set_u_nume] = useState("");
    const [u_email, set_set_u_email] = useState("");
    const [u_telefon, set_set_u_telefon] = useState("");
    const [p_nume, set_set_p_nume] = useState("");
    const [p_email, set_set_p_email] = useState("");
    const [p_telefon, set_set_p_telefon] = useState("");
    const [p_rol, set_set_p_rol] = useState("");
    const [f_locatie, set_set_f_locatie] = useState("");
    const [f_descriere, set_set_f_descriere] = useState("");
    const [ip, setIP] = useState("");
    const [modal, setModal] = useState(false);
    const inscriereRef = useRef(null);

    function showModal(mesaj,tip){
        if (tip === "eroare"){
            __modal_title=(<div><FaExclamationTriangle className="color-yellow fs-4 mb-1"/> Eroare</div>);
        }else{
            __modal_title=(<div><FaCheckSquare className="color-yellow fs-4 mb-1"/> Succes</div>);
        }
        __modal_content=mesaj;
        console.log(mesaj);
        setModal(true);
    }
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
      };
      useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
      }, []);
    let handleSubmit = async (e) => {
        e.preventDefault();
        let cookie_tag= cookies.get('submit_id1');
        if (cookie_tag){
            showModal("Deja ati trimis un raspuns!" , "eroare");
            return;
        }else {
            cookie_tag= randomstring.generate();
        }
        try {
          let res = await fetch("https://api.galaprahovei.ro/add", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              u_nume: u_nume,
              u_telefon: u_telefon,
              u_email: u_email,
              p_nume: p_nume,
              p_telefon: p_telefon,
              p_email: p_email,
              p_rol: p_rol,
              f_locatie: f_locatie,
              f_descriere: f_descriere,
              ip_address : ip,
              cookie : cookie_tag
            }),
          });
          let resJson = await res.json();
          if (res.status === 201) {
            set_set_u_nume("");
            set_set_u_email("");
            set_set_u_telefon("");
            set_set_p_nume("");
            set_set_p_email("");
            set_set_p_telefon("");
            set_set_f_descriere("");
            document.getElementById("textarea").value="";
            //cookies.set('submit_id' ,cookie_tag, { path: '/', expires: (new Date(2025, 8, 1, 10, 19, 50)) });
            showModal("Tocmai am primit raspunsul tau!\n Iti multumim!" ,"success");
        }else if (res.status === 422) {
            showModal(resJson.errors[0].msg ,"eroare");
        }
        else {
            showModal(resJson.error_text ,"eroare");
        }
    } catch (err) {
          console.log(err);
        }
      };
    return (
        <div className="container-fluid justify-content-center" style={{backgroundColor: "#424142" , color:"#D2AB67"}}>
        <PureModal
            header={__modal_title}
            footer={
                <div className="w-100 mx-auto d-none">
                    <button className="w-25" style={{color: "white", backgroundColor: "#424142", border: "none",borderRadius: "0.25em" }} >OK</button>
                </div>
            }
            isOpen={modal}
            onClose={() => {
                setModal(false);
                return true;
            }}
            >
            <p>{__modal_content}</p>
        </PureModal>
            <div className="container" id="propune-un-prahovean">
                <div className="row justify-content-center py-4 px-0">
                    <div className="mx-auto col-12 fs-3 p-0 text-left mb-2 dunga_misto" >  <FaFileSignature className="mb-2 fs-2"/> FORMULAR DE NOMINALIZARE</div>
                    <div className="col-12  ">
                        <form onSubmit={handleSubmit}>
                            <div className="row justify-content-evenly text-nowrap  ">
                                <div className="row ">
                                    <div className="col-md-6 col-12 ">
                                        <div className="row">
                                            <div className="col-12 py-1 fs-5 ">
                                                <label><strong>Datele dumneavoastră </strong></label>
                                            </div> 
                                            <div className="col-12 py-1">
                                                <label style={{color: "white"}}> Nume și prenume * </label>
                                                <input type="text" value={u_nume} placeholder="Ion Popescu" onChange={(e) => set_set_u_nume(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                            <div className="col-lg-6 col-md-12 py-1">
                                                <label style={{color: "white"}}> Număr de telefon * </label>
                                                <input type="text" value={u_telefon} placeholder="+4070000000" onChange={(e) => set_set_u_telefon(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                            <div className="col-lg-6 col-md-12 py-1">
                                                <label style={{color: "white"}}> Adresă de mail * </label>
                                                <input type="text" value={u_email} placeholder="email@example.com" onChange={(e) => set_set_u_email(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12" >
                                        <div className="row">
                                            <div className="col-12 py-1 fs-5">
                                                <label><strong>Datele persoanei(lor) nominalizate</strong></label>
                                            </div> 
                                            <div className="col-12 py-1">
                                                <label style={{color: "white"}}> Nume  * </label>
                                                <input type="text" value={p_nume} placeholder="Ion Popescu" onChange={(e) => set_set_p_nume(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                            <div className="col-lg-6 col-md-12 py-1">
                                                <label style={{color: "white"}}> Număr de telefon </label>
                                                <input type="text" value={p_telefon} placeholder="+4070000000" onChange={(e) => set_set_p_telefon(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                            <div className="col-lg-6 col-md-12 py-1">
                                                <label style={{color: "white"}}> Adresă de mail </label>
                                                <input type="text" value={p_email} placeholder="email@example.com" onChange={(e) => set_set_p_email(e.target.value)} className="w-100 px-2 form-control input-gala"/>
                                            </div>
                                            <div className="col-xl-6 col-lg-12 py-1">
                                                <label style={{color: "white"}}> A realizat fapta bună din rolul de: * </label>
                                                <select onChange={(e) => set_set_p_rol(e.target.value)} className="form-select">
                                                    <option value="ni" selected>Va rugăm selectați ... </option>
                                                    <option value="persoana_fizica" >Persoană fizică </option>
                                                    <option value="echipa" >Echipă </option>
                                                    <option value="persoana_juridica" >Persoana juridică</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-6 col-lg-12 py-1">
                                                <label style={{color: "white"}}> Zona în care a avut loc fapta bună : *</label>
                                                <select onChange={(e) => set_set_f_locatie(e.target.value)} className="form-select">
                                                    <option value="ni" selected>Va rugăm selectați ... </option>
                                                    <option value="rural" >Rural </option>
                                                    <option value="urban" >Urban</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-wrap px-4 py-2" >
                                    <label  className="col-12 py-1 fs-4"><strong>Descrierea faptei bune </strong></label>
                                    <textarea id="textarea" className="w-100 px-2 form-control input-gala mb-4" style={{minHeight: "8em"}} onChange={(e) => set_set_f_descriere(e.target.value)}>
                                        
                                    </textarea>
                                    <span style={{color: "white", fontSize : "1.3em"}}>
                                        Vă rugăm să descrieți în aproximativ 300 de cuvinte (jumătate de pagină) fapta bună pe care o înscrieți la Gala Comunității Prahovene. <br/>
                                        <ul>
                                            <li>Fiecare nominalizare presupune înscrierea unei singure fapte bune. Dacă doriți să înscrieți mai multe fapte bune sau mai multe persoane, vă rugăm să realizați câte o înscriere separată pentru fiecare faptă bună. <br/> </li> 
                                            <li>Consultați criteriile de înscriere din cadrul <a href="https://documente.code-envision.ro/regulament_galaph.pdf" style={{color: "white" }}>Regulamentul Galei Comunității Prahovene</a> </li>
                                            <li>Pentru o descriere mai clară, vă rugăm să aveți în vedere următoarele: descrierea cât mai concretă a acțiunii, cine este autorul acțiunii, cine este beneficiarul/beneficiarii acțiunii, când și unde a avut loc fapta bună. </li>
                                        </ul>
                                    </span>
                                    <div className="col-lg-6 col-md-12 py-1">
                                        <button type="submit" className="w-100 px-2 form-control input-gala bg-white color-black">
                                            TRIMITE NOMINALIZAREA
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Inscriere;
