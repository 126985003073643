import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

import Landing from './Landing';
import Regulament from './Regulament';
import Inscriere from './Inscriere';
import Footer from './Footer';
import ScopulDemersului from './ScopulDemersului';
import Default from './Default';
import Organizatori from './Organizatori';
import TabelaVotare from './votare/TabelaVotare';
import PerioadaIncheiata from './PerioadaIncheiata';
import Carousel from './Carusel';
const tagManagerArgs = {
  gtmId: 'G-N5L3FCXNFP'
}

TagManager.initialize(tagManagerArgs);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Landing />
    <ScopulDemersului/>
    {/* <Regulament/> */}
      {/* <Default/> */}
    {/* <Inscriere/> */}
    {/* <PerioadaIncheiata/> */}
    {/* <Organizatori/> */}
    <TabelaVotare/>
    <Footer/>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
