import React from "react";

function CatVotare(props) {
  return (
        <div className="col-12 col-md-6 col-lg-4 text-center h-100 " onClick={props.onClick} style={{lineHeight: "1.1 !important"}} >
            <button className="py-2 bg-white color-green rounded-2 w-100 fs-5">
                {props.titlu}
            </button>
            {(props.votat ?
            (<div className="w-100 bg-yellow color-white pt-3 rounded-2" style={{marginTop: "-1em"}} >
                    VOTAT 
            </div>) 
            : "" 
            )}
        </div>
  );
}

export default CatVotare;