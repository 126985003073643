import React, { useRef, useState } from "react";
import { useEffect } from "react";
import axios, { all } from "axios";
import PersonCard2 from "./PersonCard2";
import ShowMore from 'react-show-more-button';

import { FaAngleDoubleDown, FaAngleDoubleUp,FaFileDownload,FaBookOpen } from 'react-icons/fa';

function TabelaPremianti() {
  const [people,setPeople] = useState([]);
  const [showPeople, setShowPeople] = useState(false);
  
  const [maxHeightPersoane, setMaxHeightPersoane] = useState("330em");

  function toggleShowPeople() {
    setMaxHeightPersoane( (!showPeople)?"none":"33em");
    setShowPeople(!showPeople);
    }

  useEffect( ()=>{
    fetchData();
  },[])

  async function fetchData(){
    const response = await axios.get("https://api-propuneri.galaprahovei.ro/category3/all/");
    setPeople(response.data.persoane);
  }
  function showPeopleCards(entities){
    return entities.map( (object,index) =>{
        const card =(<PersonCard2
            id={object.id}
            categoryId={people.id}
            nume={object.name}
            linkArticol={object.linkActiv}
            poza={object.photo}
            short={object.shortDescription}
            long={object.longDescription} 
            />)
          return card;
    });
  }
//   var showPeopleButton = showPeople ? ( 
//       <button 
//           className="bg-white color-blackk w-50 py-1 rounded-2" 
//           onClick={()=>toggleShowPeople()}> 
//           Vezi mai puțin <FaAngleDoubleUp/> 
//       </button>) 
//           : ( 
//       <button 
//           className="bg-white color-blackk w-50 py-1 rounded-2" 
//           onClick={()=>toggleShowPeople()}> 
//           Vezi mai mult <FaAngleDoubleDown/> 
//       </button> )
  return (
    <>
        <div className="container color-blackk fs-2 mb-2">
        </div>
        <div id="perioada_incheiata" className="container-fluid overflow-hidden bg-blackk color-white mb-5 py-5 px-3 rounded-3">
            <div className="container">
            <div className="col-12 fs-3 text-center text-uppercase text-white"> Câștigătorii votului popular Gala Comunității Prahovene 2023</div>
                <div id="platanPersoane" className=" pb-2 row gx-5 gy-4" style={{overflowY: "hidden",maxHeight: maxHeightPersoane}} >
                    {showPeopleCards(people)}
                </div>
                <div className="pb-2 row gx-5 gy-4 text-center justify-content-center my-1">
                    {/* {showPeopleButton} */}
                </div>
            </div>
        </div>
    </>
  )
}

export default TabelaPremianti;