import React from "react";
import image from "../poze/people/liviu.jpg";
import PureModal from 'react-pure-modal';
import { useState,useEffect } from "react";
import { useRef } from "react";
import axios from "axios";
import Cookies from 'universal-cookie';
import randomstring from "randomstring";
import baraSponsori from "../poze/bara.png";
const cookies = new Cookies({ path: '/' });
function PersonCard(props) {
  const [modal,setModal] = useState(false);
  const [modalContent,setModalContent] = useState(props.long);
  const [ip,setIP] = useState('unsubmitted');
  const [cookie,setCookie] = useState('untokened');
  const ref = useRef(false);

  useEffect(() => {
    getIpAddress();
    getCookieTag();
  }, []);

  const getIpAddress = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
  };
  function getCookieTag() {
    let cookie_tag= cookies.get('submit_id');
    if (cookie_tag){
      setCookie(cookie_tag);
    }else {
      cookie_tag= randomstring.generate();
      cookies.set('submit_id' ,cookie_tag, { path: '/', expires: (new Date(2025, 8, 1, 10, 19, 50)) });
      setCookie(cookie_tag);
    }
  };
  async function votePerson() {
      var formData= new FormData();
      formData.append("ipAddress",ip);
      formData.append("cookieTag",cookie);
      formData.append("personId",props.id);
      formData.append("categoryId",props.categoryId);
      const response = await axios.post("https://api-propuneri.galaprahovei.ro/person/vote",formData,{
        headers:{
          'Content-Type': 'application/json'
        }
      }).then( (response)=> {
        setModalContent(response.data.message);
        setModal('success');
        props.voteCallback(props.id,props.categoryId);
      })
      .catch( (error)=> {
        setModalContent(error.response.data.message);
        setModal('eroare');
      })
      console.log(response);
  }
  const scrollToCategories = () => {
    var element_to_scroll_to = document.getElementById('categorii_votare');
    element_to_scroll_to.scrollIntoView();
  };
  return (
    <div id={'card'+props.id} key={props.id} className="pt-4 col-6 col-sm-6 col-lg-4 col-xl-3 show-box ">
        <PureModal
            header={props.nume}
            footer={
                <div className="mx-auto row gx-2 overflow-hidden">
                    {(modal=='info' ? 
                    (
                    <>
                    <div className="col-12">
                      <button className="mr-2 mb-3 w-100 btn px-2 py-1 bg-white text-black" onClick={() => {setModal(false);}}>
                        INCHIDE
                      </button>
                    </div>
                    <div className="col-12">
                      <a className="w-100 btn px-2 py-1 bg-white text-black" onClick={() => {window.open(props.linkArticol, "_blank"); }}>
                        CITEȘTE ARTICOL
                      </a>
                    </div>
                    </>
                    ) : "" 

                    )}
                    {(modal=='success' || modal=='eroare' ? 
                    (<div className="col-12">
                      <button className="text-uppercase w-100 btn px-2 py-1 bg-blackk text-white" onClick={() => {setModal(false);scrollToCategories();}}>
                        INTOARCE-TE LA CATEGORII
                      </button>
                    </div>) : "" 
                    )}
                </div>
            }
            isOpen={modal}
            onClose={() => {
                setModal(false);
                return true;
            }}
            >
            <p>{modalContent}</p>
        </PureModal>
          <div className="row pt-0 pb-3 rounded-1 h-100 bg-white z-2" style={{backgroundColor: "#899971" }}>
              <div className="col-12 p-0">
                  <img className="w-100" src={props.poza}/>
              </div>
              <div className="col-12 py-2 px-3">
                  <img className="col-12 p-0" src={baraSponsori} />
              </div>
              {/* <div className="mt-3 col-12 fs-3 color-blackk text-uppercase fw-bold">
                  {props.nume}
              </div> */}
              {/* <div className="w-75 mt-2 mx-auto bg-blackk opacity-50 rounded-3" style={{height: "2px"}}></div> */}
              <div className="text-black text-justify">
                  {props.short}
              </div>
              <div className="col-12 mt-2 overflow-hidden">
                <div className="row gy-2">
                  <div className="col-12 col-lg">
                    <button className="btn bg-blackk text-white px-1 py-1 w-100 rounded-1" onClick={()=>{setModal('info');setModalContent(props.long)} }> DETALII</button>
                  </div>
                  {( !props.votat ?
                  (<div className="col-12 col-lg">
                    <button className="btn bg-yellow text-white px-1 py-1 w-100 rounded-1 text-uppercase" onClick={()=>votePerson()}> VOTEAZă</button>
                  </div>): "")}
                </div>
              </div>
          </div>
              {(props.votat ?
                (<div className="row text-center" >
                        <div className="col-12 bg-yellow fixed text-center py-0" style={{marginTop: "-0.5em",borderRadius: "0 0 5px 5px" }}> VOTAT </div> 
                </div>) 
                : "" 
                )}
    </div>
    
  );
}
export default PersonCard;