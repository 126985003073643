import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook} from "@fortawesome/free-brands-svg-icons";
import './Footer.css';
const Footer = () => {
    const footer_element = {
        maxWidth: "40em"
    };
    return (
        <div className="py-2 container-fluid px-5 pb-4 text-nowrap" style={{ backgroundColor: "#424142", color : "white" }}>
            <div className="row justify-content-center">
                <div className="col-md-3 col-12" style={footer_element}>
                    <div className="col-12 fs-3 text-center " style={{color: "#D2AB67"}}> Social Media</div>
                    <div className="d-flex justify-content-center w-75">
                        <a href="https://www.facebook.com/GalaComunitatiiPrahovene" className="text-white px-2 py-1 fs-5" style={{ textDecoration: "none", color:"white", height: "3em" }}> <FontAwesomeIcon icon={faFacebook} /> Gala Comunității Prahovene</a>
                    </div>
                </div>
                <div className="col-md-5 col-12" style={footer_element}>
                    <div className="row">
                        <div className="col-12 fs-3 text-center " style={{color: "#D2AB67"}}> Despre Noi</div>
                        <div className="col-xxl-6 col-12 ">
                                <div className="col-12 fs-5 mb-3332"> Fundația Comunitară Prahova </div>
                                <div className="col-12 ">Strada Maramureș, nr 12, P40</div>
                                <div className="col-12 ">Ploiești</div>
                                <div className="col-12  mb-2">Romania</div>
                                <div className="col-12 ">Isabela Alexe - <strong> 0726.382.864 </strong></div>
                                <div className="col-12 ">Alina Mustatea - <strong>0740.302.235</strong></div>
                        </div>
                        <div className="col-xxl-6 col-12 mt-xl-0 mt-3">
                                <div className="col-12 fs-5 mb-2">Observatorul Prahovean</div>
                                <div className="col-12 ">Strada Lupeni, nr. 6B</div>
                                <div className="col-12  mb-2">Ploiești</div>
                                <div className="col-12 ">România</div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-12" style={footer_element}>
                    <div className="row">
                        <div className="col-12 fs-3 text-center " style={{color: "#D2AB67"}}> Informații</div>
                        <a href="https://documente.code-envision.ro/regulament_galaph.pdf" style={{ textDecoration: "none", color:"white"}} className="col-12">Termeni și condiții</a>
                        <a className="col-12 " href="#" style={{color: "white" , textDecoration: "none"}} id="open_preferences_center">Politica cookies</a>
                        <a className="col-12 " href="https://api-propuneri.galaprahovei.ro/static/regulament_galaph_actualizat_07.11.2023.pdf" style={{color: "white" , textDecoration: "none"}} id="open_preferences_center">Regulament</a>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;