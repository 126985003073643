import React from "react";
import PureModal from 'react-pure-modal';
import { useState,useEffect } from "react";
import { useRef } from "react";
import Cookies from 'universal-cookie';
import baraSponsori from "../poze/bara.png";
const cookies = new Cookies({ path: '/' });
function PersonCard2(props) {
  const [modal,setModal] = useState(false);
  const [modalContent,setModalContent] = useState(props.long);
  const ref = useRef(false);

  const scrollToCategories = () => {
    var element_to_scroll_to = document.getElementById('categorii_votare');
    element_to_scroll_to.scrollIntoView();
  };
  return (
    <div id={'card'+props.id} key={props.id} className="pt-4 col-6 col-sm-6 col-lg-4 col-xl-3 show-box ">
        <PureModal
            header={props.nume}
            footer={
                <div className="mx-auto row gx-2 overflow-hidden">
                    <div className="col-12">
                      <button className="mr-2 mb-3 w-100 btn px-2 py-1 bg-white text-black" onClick={() => {setModal(false);}}>
                        INCHIDE
                      </button>
                    </div>
                    {
                        ( (props.linkArticol != undefined)  ? 
                        (
                            <div className="col-12">
                                <a className="w-100 btn px-2 py-1 bg-white text-black" onClick={() => {window.open(props.linkArticol, "_blank"); }}>
                                    CITEȘTE ARTICOL
                                </a>
                            </div>
                        )
                        : "")
                    }
                </div>
            }
            isOpen={modal}
            onClose={() => {
                setModal(false);
                return true;
            }}
            >
            <p>{modalContent}</p>
        </PureModal>
          <div className="row pt-0 pb-3 rounded-1 h-100 bg-white z-2" style={{backgroundColor: "#899971" }}>
              <div className="col-12 p-0">
                  <img className="w-100" src={props.poza}/>
              </div>
              <div className="text-black text-justify">
                  {props.short}
              </div>
              <div className="col-12 mt-2 overflow-hidden">
                <div className="row gy-2">
                  <div className="col-12 col-lg">
                    <button className="btn bg-blackk text-white px-1 py-1 w-100 rounded-1" onClick={()=>{setModal('info');setModalContent(props.long)} }> DETALII</button>
                  </div>
                </div>
              </div>
          </div>
    </div>
    
  );
}
export default PersonCard2;