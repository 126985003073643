import React from "react";
import './Landing.css';
import logo_central from './poze/logo_centru.png'
import poza_din_fund from './poze/poza_fund.jpg';
import './animatie_show.css';
import { FaAngleDoubleDown, FaAngleDoubleLeft } from "react-icons/fa";
import baraSponsoriMare from "./poze/baraSponsoriMare.png";

const Landing = () => {
    const handleClick = () => {
        var element_to_scroll_to = document.getElementById('perioada_incheiata');
        element_to_scroll_to.scrollIntoView();
      };
    return (
        <div className="container-fluid" style={{height: "55em", backgroundImage: `url(${poza_din_fund})`,backgroundRepeat: "no-repeat",backgroundSize: "cover"}} >
            <div className="row align-items-center h-75">
                <div className="col mx-auto">
                    <div className="container-fluid text-center">
                        <div className="row justify-content-center">
                           <div className="col-12 text-center show-logo" >
                                <img className="landing_logo" src={logo_central} style={{marginTop: "-7em"}} alt="logo_image" />
                            </div> 
                            {/* <div onClick={handleClick} to="#hash-scroll-propune" className="bg-yellow color-white landing_logo my-3 fs-6 p-4 rounded-4">
                            Votează nominalizații <FaAngleDoubleDown className="mb-1"/>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Landing;