import React, { useRef, useState } from "react";
import CatVotare from "./CatVotare";
import PersonCard from "./PersonCard";
import { useEffect } from "react";
import axios, { all } from "axios";
import Cookies from 'universal-cookie';
import randomstring from "randomstring";
import ScopulDemersului from "../ScopulDemersului";
import Carusel from "../Carusel";
import PersonCard2 from "./PersonCard2";
const cookies = new Cookies({ path: '/' });
function TabelaVotare() {
  const [showMore,setShowMore] = useState(false);
  const [allPeople,setAllPeople] = useState([]);
  const [cookieTag,setCookieTag] = useState('1');

  useEffect( ()=>{
    fetchData();
  },[])

  async function fetchData(){
    const response = await axios.get("https://api-propuneri.galaprahovei.ro/category/all/"+getCookieTag());
    setAllPeople(response.data);
    console.log(response.data);
  }
  function getCookieTag() {
    let cookie_tag= cookies.get('submit_id');
    if (cookie_tag){
      return cookie_tag;
    }else {
      cookie_tag= randomstring.generate();
      cookies.set('submit_id' ,cookie_tag, { path: '/', expires: (new Date(2025, 8, 1, 10, 19, 50)) });
      return cookie_tag;
    }
  };
  async function voteChange(personID,categoryID){
    var categoryObject =allPeople.find( (element) => element.id==categoryID );
    var personArrayOfCat=categoryObject.person;
    for (let person of personArrayOfCat) {
      if (person.id!=personID)
        person.voted=false;
      else
        person.voted=true;
    };
    await fetchData();
    setShowMore(categoryObject);
  }
  function showPlatan(people){
    if (people){
      if (Object.keys(people.person).length == 0) return ( <div className="fs-5"> Nu există persoane nominalizate aici</div>);
      return people.person.map( (object,index) => {
        const card =(<PersonCard2 
          id={object.id}
          votat={object.voted}
          categoryId={people.id}
          nume={object.name}
          linkArticol={object.linkActiv}
          poza={object.photo}
          short={object.shortDescription}
          long={object.longDescription} 
          voteCallback={voteChange}
          />)
          console.log(object);
        return card;
      }
      );
    }else return (
      <>
        <div className="fs-5 pb-0">
          Selectează o categorie pentru a vedea persoanele nominalizate. <br/>
        </div>
      </>
      );
  }
  function emptyPlatan(){
    document.getElementById("platan").classList.add('unshow-box');
    console.log(document.getElementById("platan"));
    setTimeout( ()=> document.getElementById("platan").classList.remove('unshow-box') ,170 )
  }
  const scrollToPlatan = () => {
    var element_to_scroll_to = document.getElementById('platan');
    element_to_scroll_to.scrollIntoView();
  };
  return (
    <>
    <div className="container color-blackk fs-2 mb-2">
      <div className="col-12 text-center text-uppercase"> NOMINALIZAȚI GALA COMUNITĂȚII PRAHOVENE 2023</div>
    </div>
    <div id="perioada_incheiata" className="pt-4 mt-5 pb-4 container-fluid overflow-hidden bg-blackk color-white mb-5 pb-5" style={{width: "85%", borderRadius: "1em",backgroundColor: "white", marginTop: "-6em" }}>
        <div className="container">
            <div id="platan" className=" pb-2 row gx-5 gy-4">
              { showPlatan(showMore) }
            </div>
            {/* <div className="row fs-3 color-yellow">
                Selecteaza o categorie 
            </div> */}
            <div className="row gx-5 gy-4 mt-4" id="categorii_votare">
              {allPeople.map((object) => (
                <CatVotare 
                  titlu={object.name}
                  votat={object.voted}
                  id={object.id} 
                  onClick={
                    () => {
                      if (showMore.name!=object.name ){
                        setTimeout( () => setShowMore(object), 170);
                        emptyPlatan();
                        scrollToPlatan();   
                      }
                    } }
                />
                ))}
            </div>
        </div>
    </div>
    </>
  );
}

export default TabelaVotare;