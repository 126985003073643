import React, { useState } from "react";
import { FaAngleDoubleDown, FaAngleDoubleUp, FaPlay } from 'react-icons/fa';
import { TypeWriter } from "react-typewritter";
import TabelaVotare from "./votare/TabelaVotare";
import baraSponsoriMare from "./poze/baraSponsoriMare.png";
import baraInvitatieGala from "./poze/gcp-cover-facebook_1920w.jpg";
import TabelaSpecial from "./votare/TabelaSpecial";
import TabelaPremianti from "./votare/TabelaPremianti";
import Carusel from "./Carusel";
const ScopulDemersului = ({ children }) => {
    const [show, setShow] = useState();
  
    function toggleShow() {
      setShow(!show);
    }
    var buttonText = show ? ( <> Vezi mai putin <FaAngleDoubleUp /> </>) : ( <> Vezi mai mult <FaAngleDoubleDown/> </> )
    return (
        <>
            <div className="row justify-content-center" style={{marginTop: "-22em" ,marginBottom: "3em" , backgroundColor: "none", borderRadius: "2em"}}>
                <img src={baraSponsoriMare} className="col-10 col-md-6 col-sm-8 p-3" style={{maxWidth:"45em",opacity: "1 !important",borderRadius: "2em"}}/>
            </div>
            <div className="container justify-content-center" style={{color: "#424142"}}>
                <div className="container p-4 bg-green rounded-4 mb-5">
                    {/* <img src={baraInvitatieGala} className="w-100"/> */}
                    
                    <div className="text-uppercase text-center pb-3 color-white fs-4">
                    Gala Comunității Prahovene, ediția 2023 - VIDEO
                    </div>
                    <div class='embed-container'>
                        <iframe src="https://www.youtube.com/embed/1HjKR8qId5Y" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
                <div className="container p-4 bg-green rounded-4 mb-5">
                    {/* <img src={baraInvitatieGala} className="w-100"/> */}
                    
                    <div className="text-uppercase text-center pb-3 color-white fs-4">
                    Galerie foto ediția 2023
                    </div>
                    <div class=''>
                        <Carusel/>
                    </div>
                </div>
                <TabelaPremianti/>
                <TabelaSpecial/>
                {/* <div className="mx-auto row p-4 justify-content-center shadow-lg " style={{width: "85%", borderRadius: "1em",backgroundColor: "white", marginTop: "-10em" }} >
                    <div className="col-12 col-md-11 fs-2 text-left mb-2 text-uppercase" > <FaPlay className="fs-3 mb-1"/> <TypeWriter interval="50" elementType="span" text="Scopul demersului "> </TypeWriter></div>
                    <div className="col-12 col-md-11 fs-5 text-left ">
                        Mai ales în contextul social în care ne aflăm, noi credem că este important ca adevăratele valori ale unei comunități să fie propuse ca modele reale. Prahova este un rezervor nelimitat de astfel de modele.
                        <br></br>
                        <p className="mt-4"> 
                        <TypeWriter delay="2000" interval="30" elementType="span" text="Este vremea să le prezentăm întregii comunități, să le arătăm prețuirea noastră și, de ce nu, să le propunem ca exemple de succes. "> </TypeWriter> </p>
                        <div className="col-xl-3 col-lg-4 col-md-5 col-12 px-3 py-1 text-center rounded-3 show-button text-nowrap" style={{backgroundColor: "#424142" , color:"white"}} onClick={toggleShow} >
                            {buttonText}
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="container-fluid mt-3 shadow-lg " style={{backgroundColor: "#424142" , color:"white"}}>
                <div className="row justify-content-center">
                    { show && children }
                </div>
            </div>
        </>
    );

}
export default ScopulDemersului;
