import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
export default function Carusel(){
    return (
        <Carousel>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(1).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(2).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(3).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(4).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(5).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(6).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(7).jpg" /></div> */}
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(8).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(9).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(10).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(11).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(12).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(13).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(14).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(15).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(16).jpg" /></div> */}
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(17).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(18).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(19).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(20).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(21).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(22).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(23).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(24).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(25).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(26).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(27).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(28).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(29).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(30).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(31).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(32).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(33).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(34).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(35).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(36).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(37).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(38).jpg" /></div> */}
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(39).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(40).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(41).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(42).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(43).jpg" /></div> */}
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(44).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(45).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(46).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(47).jpg" /></div> */}
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(48).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(49).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(50).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(51).jpg" /></div>
            <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(52).jpg" /></div>
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(53).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(54).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(55).jpg" /></div> */}
            {/* <div><img src="https://api-propuneri.galaprahovei.ro/static/poze_gala(56).jpg" /></div> */}
        </Carousel>
    );
}

